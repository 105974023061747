import React, { useState } from 'react';
import styles from '../loginAndSignupStyles.module.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import logo from '../../../assets/logo/darkFindyLogo.png';
import { supabase } from '../../../servieces/supabase/supabaseClient';
import Page from '../../Page';
import { Button } from '../../../sharedPacakge/components/Button/Button';

export const doPasswordReset = async (email: string): Promise<any> => {
  try {
    const redirectUrl = `${window.location.origin}${Page.confirmResetPassword}`;
    const { data, error } = await supabase.auth.resetPasswordForEmail(
      email.toLowerCase(),
      {
        redirectTo: redirectUrl
      }
    );
    if (error) {
      throw error;
    }
    return data;
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};

export const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordResetMessageSent, setPasswordResetMessageSent] =
    useState<boolean>(false);

  const navigate = useNavigate(); // Use navigate instead of redirect

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage('');

    if (!email) {
      setErrorMessage('Email påkrævet');
      return;
    }

    setIsLoading(true);
    try {
      await doPasswordReset(email);
      setPasswordResetMessageSent(true);
      setTimeout(() => {
        navigate(Page.login);
      }, 2500);
    } catch {
      setErrorMessage('Der opstod en fejl');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className={styles.container}>
      <img
        className={styles.logo}
        src={logo}
        alt="findy-logo"
        width={100}
        height={70}
      />

      <form className={styles.form} onSubmit={onSubmit}>
        {passwordResetMessageSent ? (
          <p>Et nulstillings link er blevet sendt til din email</p>
        ) : (
          <>
            <h1 className={styles.title}>Glemt din adgangskode?</h1>
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              variant="outlined"
              disabled={isLoading}
            />
            {errorMessage && (
              <p className={styles.errorMessage}>{errorMessage}</p>
            )}
            <Button disabled={isLoading} type="submit" fullWidth>
              Nulstil din adgangskode
            </Button>
            <p className={styles.noUser__text}>
              Tilbage til{' '}
              <Link className={styles.noUser__link} to={'/login'}>
                Login
              </Link>
            </p>
          </>
        )}
      </form>
    </main>
  );
};
