import React, { FC, useState } from 'react';
import styles from './CreateOrganisationForm.module.css';
import { TextField } from '@mui/material';
import Api from '../../../servieces/Api/Api';
import { useAuth } from '../../../contexts/AuthContext/AuthContext';
import { LoadingSpinner } from '../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import { Button } from '../../../sharedPacakge/components/Button/Button';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';

interface Props {
  onOrganisationCreated?: (locationId: number) => void;
}

export const CreateOrganisationForm: FC<Props> = ({
  onOrganisationCreated
}) => {
  const { user } = useAuth();
  const [name, setName] = useState<string>('');
  const [locationName, setLocationName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (name === '') return alert('din organisation mangler et navn');
    if (locationName === '') return alert('din lokoation mangler et navn');
    if (!user) return;

    setLoading(true);
    Api.post
      .organisation({
        userId: user.id,
        organisationName: name,
        locationName
      })
      .then((reposne) => {
        onOrganisationCreated && onOrganisationCreated(reposne.locationId);
      })
      .catch(() => setLoading(false));
  };

  return (
    <form onSubmit={onSubmit} className={styles.form}>
      <TextField
        id="outlined-basic"
        label="Navn på din organisation"
        placeholder="F.eks. Findy"
        variant="outlined"
        onChange={(e: any) => setName(e.target.value)}
        fullWidth
        required
        disabled={loading}
        InputProps={{
          startAdornment: (
            <BusinessOutlinedIcon
              style={{ width: 22, height: 22, marginRight: 6 }}
            />
          )
        }}
      />
      <div>
        <TextField
          id="outlined-basic"
          label="Navn på lokation"
          variant="outlined"
          placeholder="F.eks. Aarhuc C"
          onChange={(e: any) => setLocationName(e.target.value)}
          fullWidth
          required
          disabled={loading}
          InputProps={{
            startAdornment: (
              <FmdGoodOutlinedIcon
                style={{ width: 22, height: 22, marginRight: 6 }}
              />
            )
          }}
        />
        <p className={styles.tooltip}>
          Det er altid muligt at oprette flere lokationer senere.
        </p>
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Button
          type="submit"
          className={styles.submitButton}
          fullWidth
          disabled={loading}
        >
          Opret
        </Button>
      )}
    </form>
  );
};

export default CreateOrganisationForm;
