import { supabase } from '../../../supabase/supabaseClient';
import { MatchRecord } from '../../../../types/supabase/collections';
import type { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { Database } from '../../../../types/supabase/supabaseDB';
import logger from '../../../../sharedPacakge/logger';

type CustomQuery = PostgrestFilterBuilder<Database['public'], MatchRecord, any>;

export async function getMatches(args: {
  customQuery?: (query: CustomQuery) => CustomQuery;
}): Promise<MatchRecord[]> {
  const { customQuery } = args;

  let query = supabase.from('matches').select('*') as CustomQuery;

  if (customQuery) {
    query = customQuery(query);
  }

  const { data, error } = await query;

  if (error) {
    logger.error(`getMatches Error fetching matches: ${JSON.stringify(error)}`);
    throw error;
  }

  return data;
}
