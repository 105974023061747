import { redirect } from "react-router-dom";
import Api from "../../servieces/Api/Api";
import Page from "../Page";

export const redirectAfterLogin = async (userId: string) => {
  try {
    const locationId = await Api.get.anyLocationWhereUserIsAdmin(userId);

    if (locationId) {
      redirect(Page.l.actions(locationId));
    } else {
      redirect(Page.createOrganisation);
    }
  } catch (e) {
    console.warn("redirectAfterLogin", e);
    redirect(Page.createOrganisation);
  }
};
