import { useEffect, useState } from 'react';

const MAX_MOBILE_WIDTH = 500;

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < MAX_MOBILE_WIDTH);
  }, []);

  return isMobile;
};
