import {
  useLocationData,
  useSetLocationData
} from '../../../../../../contexts/LocationDataContext/LocationDataContext';
import { useSnackbar } from '../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { DayOfWeek } from '../../../../../../sharedPacakge/types';
import Api from '../../../../../../servieces/Api/Api';
import styles from './expriryDateOptions.module.css';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';

export const ExpiryDateOptions = () => {
  const { organisation, location } = useLocationData();
  const setLocationData = useSetLocationData();
  const { showSnackbar } = useSnackbar();
  const [highValueExpirySetting, setHighValueExpirySetting] =
    useState<string>('');
  const [lowhValueExpirySetting, setLowhValueExpirySetting] =
    useState<string>('');
  const [leftBehindItemExpiryDays, setLeftBehindItemExpiryDays] =
    useState<number>(0);
  const [isloading, setIsLoading] = useState(false);

  useEffect(() => {
    if (location) {
      setHighValueExpirySetting(location.high_value_item_expiry);
      setLowhValueExpirySetting(location.low_value_item_expiry);
      setLeftBehindItemExpiryDays(location.left_behind_item_expiry_days);
    }
  }, [location]);

  if (!location) return null;
  const isNumber = (value: string | number) => !isNaN(Number(value));

  const InputProps = (value: string | number) => {
    return {
      startAdornment: !isNumber(value) ? (
        <InputAdornment position="start">hver</InputAdornment>
      ) : null,
      endAdornment: isNumber(value) ? (
        <InputAdornment position="end">dage</InputAdornment>
      ) : null
    };
  };

  const isValidExpiryOption = (value: string): boolean => {
    const validDays: DayOfWeek[] = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ];
    return validDays.includes(value as DayOfWeek) || isNumber(value);
  };

  const onUpdateExpirySettingClick = (e: any) => {
    e.preventDefault();

    if (!isValidExpiryOption(highValueExpirySetting)) {
      showSnackbar('Ugyldig værdi for høj værdi udløbsindstilling', 'error');
      return;
    }
    if (!isValidExpiryOption(lowhValueExpirySetting)) {
      showSnackbar('Ugyldig værdi for lav værdi udløbsindstilling', 'error');
      return;
    }

    Api.put
      .location(location.id, {
        high_value_item_expiry: highValueExpirySetting,
        low_value_item_expiry: lowhValueExpirySetting,
        left_behind_item_expiry_days: leftBehindItemExpiryDays
      })
      .then(() => {
        setLocationData((prevState) => {
          if (!prevState.isLoading && prevState.location) {
            return {
              ...prevState,
              location: {
                ...prevState.location,
                high_value_item_expiry: highValueExpirySetting,
                low_value_item_expiry: lowhValueExpirySetting,
                left_behind_item_expiry_days: leftBehindItemExpiryDays
              }
            };
          }

          return prevState;
        });
        showSnackbar('udløbsdatoer blev opdateret');
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'));
  };
  return (
    <form onSubmit={onUpdateExpirySettingClick} className={styles.form}>
      <TextField
        fullWidth
        id="outlined-basic"
        label="Hvor længe opbevares genstande over 500 kr."
        placeholder={`Antal dage eller en dag, såsom "friday"`}
        variant="outlined"
        value={highValueExpirySetting}
        onChange={(e: any) => setHighValueExpirySetting(e.target.value)}
        disabled={isloading}
        InputProps={InputProps(highValueExpirySetting)}
      />
      <TextField
        fullWidth
        id="outlined-basic"
        label="Hvor længe opbevares genstande under 500 kr."
        placeholder={`Antal dage eller en dag, såsom "friday"`}
        variant="outlined"
        value={lowhValueExpirySetting}
        onChange={(e: any) => setLowhValueExpirySetting(e.target.value)}
        disabled={isloading}
        InputProps={InputProps(lowhValueExpirySetting)}
      />
      <TextField
        fullWidth
        id="outlined-basic"
        label="Hvor mange dage har en kunde til at afhendte deres fundne genstand?"
        placeholder={`Antal dage`}
        variant="outlined"
        type="number"
        value={leftBehindItemExpiryDays}
        onChange={(e: any) => setLeftBehindItemExpiryDays(e.target.value)}
        disabled={isloading}
        InputProps={InputProps(leftBehindItemExpiryDays)}
      />
      <Button type="submit">Opdater</Button>
    </form>
  );
};
