import { useSearchParams } from 'react-router-dom';

// Define the SearchParams type with the isArchived filter
// interface SearchParams {
//
// }

export interface Filters {
  query: string;
  // page: number;
  // filters: string[];
  isArchived: boolean;
}

// Function to parse search parameters from URL
const parseSearchParams = (searchParams: URLSearchParams): Partial<Filters> => {
  const params: Partial<Filters> = {};

  if (searchParams.has('query')) {
    params.query = searchParams.get('query') as string;
  }
  // if (searchParams.has("page")) {
  //   params.page = parseInt(searchParams.get("page") as string, 10);
  // }
  // if (searchParams.has("filters")) {
  //   params.filters = searchParams.get("filters")?.split(",") || [];
  // }
  if (searchParams.has('isArchived')) {
    params.isArchived = searchParams.get('isArchived') === 'true';
  }

  return params;
};

// Function to serialize search parameters to URL
const serializeSearchParams = (params: Partial<Filters>): URLSearchParams => {
  const searchParams = new URLSearchParams();

  if (params.query) {
    searchParams.set('query', params.query);
  }
  // if (params.page !== undefined) {
  //   searchParams.set("page", params.page.toString());
  // }
  // if (params.filters) {
  //   searchParams.set("filters", params.filters.join(","));
  // }
  if (params.isArchived !== undefined) {
    searchParams.set('isArchived', params.isArchived.toString());
  }

  return searchParams;
};

// Custom hook to use and update search parameters
const useFilters = (): [
  Partial<Filters>,
  (params: Partial<Filters>) => void
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = parseSearchParams(searchParams);

  const updateSearchParams = (newParams: Partial<Filters>) => {
    const updatedParams = { ...params, ...newParams };
    setSearchParams(serializeSearchParams(updatedParams));
  };
  return [params, updateSearchParams];
};

export default useFilters;
