import {
  Location,
  Organisation,
  Role
} from '../../../../types/supabase/collections';
import { sendEmail } from '../../../../servieces/sendEmail';
import { baseUrl } from '../../../../utils/constants';
import Page from '../../../Page';

export const sendAdminInviteEmail = async (
  role: Role,
  email: string,
  location: Location,
  organisation: Organisation,
  userExistsAlready: boolean
) => {
  const path = userExistsAlready ? Page.l.actions(location.id) : Page.signup;
  const link = `${baseUrl}${path}`;

  if (role === Role.LocationAdmin) {
    await sendEmail({
      emails: [email],
      subject: `Du er blevet tilføj til ${location?.name} hos ${location.name}`,
      htmlContent: `<div>Du er blevet tilføjet til lokationen: ${location?.name} - hos organisationen: ${organisation.name}
                    <a href='${link}'>Gå til ${location?.name}</a>
                  </div>`
    });
  } else if (role === Role.OrganisationAdmin) {
    await sendEmail({
      emails: [email],
      subject: `Du er blevet tilføj organisationen ${organisation.name}`,
      htmlContent: `<div>Du er blevet tilføjet til organisationen: ${organisation.name}
                  <a href='${link}'>Gå til organisationen</a>

                </div>`
    });
  }
};
