import { supabase } from '../../../supabase/supabaseClient';
import { FoundItemRecord } from '../../../../types/supabase/collections';
import logger from '../../../../sharedPacakge/logger';

export const updateFoundItem = async (
  foundItemId: number,
  newData: Partial<FoundItemRecord>
): Promise<void> => {
  const { error } = await supabase
    .from('found_items')
    .update(newData)
    .eq('id', foundItemId);

  if (error) {
    logger.supabaseError('updateFoundItem', error);
    throw error;
  }
};
