import Api from '../Api/Api';
import { ItemStatus } from '../../sharedPacakge/types/ItemStatus';
import { sendEmail } from '../sendEmail';
import logger from '../../sharedPacakge/logger';
import {
  FoundItem,
  Inquiry,
  Location,
  MatchRecord,
  Organisation
} from '../../types/supabase/collections';
import { incrementDateByDays } from '../../utils/incrementDateByDays';

const getNewExpiryDateForMatch = (
  foundItem: FoundItem,
  inquiry: Inquiry,
  location: Location
) => {
  const locationExpiryDate = incrementDateByDays(
    new Date(),
    location.left_behind_item_expiry_days
  );
  const dates = [
    new Date(foundItem.expiry_date).getTime(),
    new Date(inquiry.expiry_date).getTime(),
    locationExpiryDate.getTime()
  ];
  const latestDate = new Date(Math.max(...dates));
  return latestDate;
};

export const approveMatch = async (args: {
  selectedMatch: {
    matchData: MatchRecord;
    inquiry: Inquiry;
  };
  foundItem: FoundItem;
  location: Location;
  organisation: Organisation;
}): Promise<void> => {
  const { selectedMatch, foundItem, organisation, location } = args;

  try {
    await Api.put.match(selectedMatch.matchData.id, {
      match_confirmed: true
    });

    await Api.delete.matches((query) =>
      query.eq('found_item_id', foundItem.id).eq('match_confirmed', false)
    );

    await Api.delete.matches((query) =>
      query
        .eq('inquiry_id', selectedMatch.inquiry.id)
        .eq('match_confirmed', false)
    );

    const newExpiryDate = getNewExpiryDateForMatch(
      foundItem,
      selectedMatch.inquiry,
      location
    );

    const inquiryResponse = await Api.put.inquiry(
      selectedMatch.matchData.inquiry_id,
      {
        status: ItemStatus.ReadyForPickup,
        expiry_date: newExpiryDate.toISOString()
      }
    );

    const foundItemresponse = await Api.put.foundItem(
      selectedMatch.matchData.found_item_id,
      {
        status: ItemStatus.ReadyForPickup,
        expiry_date: newExpiryDate.toISOString()
      }
    );

    sendEmail({
      emails: [selectedMatch.inquiry.reporter_email],
      subject: `Vi har fundet din(e) ${selectedMatch.inquiry.category.name_danish} hos ${organisation?.name}`,
      htmlContent: `
                  <div style="max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px;">
                    <h2 style="color: #2D7CFE;">Den er vendt tilbage!</h2>
                    <p>Kære ${selectedMatch.inquiry.reporter_name},</p>
                    <p>Vi er glade for at kunne meddele, at vi har fundet din mistede genstand.</p>
            
                    <p>Genstanden er sikker hos os, og du kan afhente den på ${location?.name} i vores åbningstider. Hvis du har spørgsmål eller brug for yderligere assistance, er du velkommen til at kontakte os.</p>
                    <p>Hvis denne meddelelse er modtaget ved en fejl, eller hvis du mener, at dette ikke er din genstand, bedes du venligst kontakte os hurtigst muligt, så vi kan rette fejlen.</p>
                    <strong>OBS: Genstanden skal afhentes indenfor ${location?.left_behind_item_expiry_days} dage, hvorefter den videregives til politiets hittegodskontor eller bortskaffes. </strong>
                    
                    <p>Med venlig hilsen,</p>
                    <p>${organisation?.name}</p>
                  </div>
              `
    });
  } catch (error) {
    logger.error(`approveMatch error ${JSON.stringify(error)}`);
    throw error;
  }
};
