import {
  FoundItemRecord,
  InquiryRecord
} from '../../../../types/supabase/collections';
import { deleteRecords, getRecords } from '../../../supabase/getRecord';
import { supabase } from '../../../supabase/supabaseClient';
import { getMatches } from '../get/getMatches';
import {
  FoundItemArchiveReason,
  InquiryArchiveReason
} from '../../../../sharedPacakge/types';
import logger from '../../../../sharedPacakge/logger';

function isFoundItem(
  item: FoundItemRecord | InquiryRecord
): item is FoundItemRecord {
  return 'found_at_location' in item && 'found_at_date' in item;
}

async function createArchiveRecords(
  items: FoundItemRecord[] | InquiryRecord[],
  archiveReason: FoundItemArchiveReason | InquiryArchiveReason
) {
  if (items.length === 0) {
    console.warn('createArchiveRecords. You are tring to archive 0 items');
    return;
  }
  if (isFoundItem(items[0])) {
    const archiveItems = (items as FoundItemRecord[]).map((item) => ({
      ...item,
      archive_reason: archiveReason
    }));
    const { error } = await supabase
      .from('archived_found_items')
      .insert(archiveItems);

    if (error) {
      logger.supabaseError('createArchiveRecords', error);
      throw error;
    }
  } else {
    const archiveItems = (items as InquiryRecord[]).map((item) => ({
      ...item,
      archive_reason: archiveReason
    }));
    const { error } = await supabase
      .from('archived_inquiries')
      .insert(archiveItems);

    if (error) {
      logger.supabaseError('createArchiveRecords', error);
      throw error;
    }
  }
}

// create the archvied row
// check if there is an approved match -> & if there is then archive the matching item (inquiry | foundItem) & the match row
// then delete the item & if there was a match also delete the matching item
export async function archiveItems(args: {
  ids: number[];
  type: 'inquiries' | 'foundItems';
  archiveReason: FoundItemArchiveReason | InquiryArchiveReason;
}): Promise<void> {
  const { ids, type, archiveReason } = args;

  // getting items
  const items =
    type === 'inquiries'
      ? await getRecords('inquiries', ids)
      : await getRecords('found_items', ids);

  if (!items || items.length === 0) {
    logger.error('archiveItems: failed to get items');
    throw new Error('archiveFoundItems: failed to get items');
  }

  // making the archiveRecrods
  await createArchiveRecords(items, archiveReason);

  // checking if they have any confirmaed matches
  const matchRecords = await getMatches({
    customQuery: (query) =>
      query
        .in(type === 'inquiries' ? 'inquiry_id' : 'found_item_id', ids)
        .eq('match_confirmed', true)
  });

  // if no confirmed matches then delete the actual records and return success
  if (!matchRecords || matchRecords.length === 0) {
    await deleteRecords(
      type === 'inquiries' ? 'inquiries' : 'found_items',
      ids
    );
    return; // this is success
  }
  // getting all the matching items
  const matchType = type === 'inquiries' ? 'foundItems' : 'inquiries';
  const matchingItemIds = matchRecords.map((match) =>
    matchType === 'inquiries' ? match.inquiry_id : match.found_item_id
  );
  const matchingItems =
    matchType === 'inquiries'
      ? await getRecords('inquiries', matchingItemIds)
      : await getRecords('found_items', matchingItemIds);

  if (!matchingItems) {
    logger.error('archiveItems missing matchingItems');
    throw new Error('archiveItems missing matchingItems');
  }

  // archiving matching items & the match records
  await createArchiveRecords(matchingItems, archiveReason);
  const { error: deleteMatchesError } = await supabase
    .from('archived_matches')
    .insert(matchRecords);

  if (deleteMatchesError) {
    logger.supabaseError('archiveItems deleteMatchesError', deleteMatchesError);
    throw deleteMatchesError;
  }

  // deleting the actual records & matching items records (the match records will cascade themselves)
  await deleteRecords(type === 'inquiries' ? 'inquiries' : 'found_items', ids);
  await deleteRecords(
    matchType === 'inquiries' ? 'inquiries' : 'found_items',
    matchingItemIds
  );
}
