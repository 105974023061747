'use client';
import React, { createContext, useContext, ReactNode } from 'react';
import { Snackbar, SnackbarType } from './Snackbar';

interface SnackbarContextProps {
  showSnackbar: (message: string, type?: SnackbarType) => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(
  undefined
);

interface SnackbarProviderProps {
  children: ReactNode;
}

const AUTOHIDE_DURATION = 4000;
export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
  children
}) => {
  const [message, setMessage] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);
  const [snackbarType, setSnackbarType] =
    React.useState<SnackbarType>('regular');

  const showSnackbar = (message: string, type: SnackbarType = 'regular') => {
    setMessage(message);
    setSnackbarType(type);
    setOpen(true);
    setTimeout(() => {
      setMessage(message);
      setOpen(false);
    }, AUTOHIDE_DURATION);
  };

  return (
    <>
      <SnackbarContext.Provider
        value={{
          showSnackbar
        }}
      >
        {children}
      </SnackbarContext.Provider>
      <Snackbar
        message={message}
        open={open}
        setOpen={setOpen}
        type={snackbarType}
      />
    </>
  );
};

export const useSnackbar = (): SnackbarContextProps => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within an SnackbarContext');
  }
  return context;
};
