import { supabase } from '../../../supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function getUserProfileFromEmail(email: string): Promise<{
  id: string;
  email: string;
  fullName: string;
} | null> {
  const queryEmail = email.toLowerCase(); // ensure its lowercase

  const { data: user, error } = await supabase
    .from('user_profiles')
    .select('id, email, full_name')
    .eq('email', queryEmail)
    .single();

  assertNoSupabaseError(user, error, 'getUserProfileFromEmail error:', {
    queryEmail
  });

  return user
    ? {
        id: user.id,
        email: user.email,
        fullName: user.full_name
      }
    : null;
}
