import React, { useState, useEffect } from 'react';
import styles from './emailTemplatesTab.module.css';
import { TabLayout } from '../../components/TabLayout/TabLayout';
import { EmailType } from '../../../../types/supabase/collections';
import EmailTypeOptions from './components/EmailTypeOptions/EmailTypeOptions';

interface Template {
  id: string;
  email_type: string;
  subject: string;
  content: string;
}

export const EmailTemplatesTab: React.FC = () => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedEmailType, setSelectedEmailType] =
    useState<EmailType>('INQUIRY_CREATED');
  // const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
  //   null
  // );

  useEffect(() => {
    // todo: fetch  for each email type in org & for the ones that don't exist return default.
    // todo: disabled skal være en coloumn i email skabelon
  }, []);

  return (
    <TabLayout>
      <div className={styles.tabContainer}>
        <EmailTypeOptions
          selectedEmailType={selectedEmailType}
          onChange={(emailType) => setSelectedEmailType(emailType)}
        />
        Kommer snart...
        {/*<div>*/}
        {/*  ENABLED / DISABLED */}
        {/*</div>*/}
        {/*<div>text showing variables someone can use</div>*/}
        {/*<div>*/}
        {/*  For nogen email typer, så giv mulighed for at ændre hvornår de sendes [her så påmind brugeren hvad de har sat i indstillinger om lokation expiry] */}
        {/*  (denne setting bliver ændret på lokationen)*/}
        {/*</div>*/}
        {/*<div>Email Subject</div>*/}
        {/*<div>Email Content</div>*/}
        {/*<div>Save || Send test email (secondary)</div>*/}
      </div>
    </TabLayout>
  );
};
