import React, { FC } from "react";
import ReactDOM from "react-dom";
import { IconButton } from "@mui/material";
import styles from "./snackbar.module.css";
import { SetState } from "../../types/utilityTypes";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
export type SnackbarType = "regular" | "success" | "error" | "warning";

interface Props {
  message: string;
  open: boolean;
  setOpen: SetState<boolean>;
  type: SnackbarType;
}
export const Snackbar: FC<Props> = ({ message, open, setOpen, type }) => {
  const handleClose = (e: any) => {
    e.preventDefault();
    setOpen(false);
  };

  const isCLientSide = typeof window !== "undefined";
  if (!isCLientSide) return null;

  const portal = document.getElementById("snackbar-root");

  if (!portal) {
    console.warn("Snackbar can't open, there is no modal root in the html");
    return null;
  }

  return ReactDOM.createPortal(
    open ? (
      <div
        className={`
        ${styles.snackbar} 
        ${type === "regular" ? styles.regularColor : ""}
        ${type === "success" ? styles.successColor : ""}
        ${type === "error" ? styles.errorColor : ""}
        ${type === "warning" ? styles.warningColor : ""}
        `}
      >
        <span>{message}</span>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseRoundedIcon />
        </IconButton>
      </div>
    ) : null,
    portal,
  );
};
