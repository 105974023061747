import React, { FC, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { DatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import 'dayjs/locale/da';
import styles from './newFoundItemButton.module.css';
import {
  CookieName,
  getBooleanCookie,
  setCookie
} from '../../../../../utils/cookies/cookie';
import dayjs from 'dayjs';
import { useLocationData } from '../../../../../contexts/LocationDataContext/LocationDataContext';
import {
  ItemCategory,
  PostFoundItemPayloadData
} from '../../../../../types/supabase/collections';
import { useAuth } from '../../../../../contexts/AuthContext/AuthContext';
import { isToday } from '../../../../../utils/isToday';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEventDispatcher } from '../../../../../contexts/EventsProvider.ts/EventsProvider';
import { SetState } from '../../../../../sharedPacakge/types/utilityTypes';
import { useSnackbar } from '../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import FindyApi from '../../../../../sharedPacakge/findyApi/fetchFindyApi';
import { scaleAndMinifyImage } from '../../../../../sharedPacakge/utils/imageScaleMinify';
import { FormModal } from '../../../../../sharedPacakge/components/Modal/FormModal/FormModal';
import { Switch } from '../../../../../sharedPacakge/components/Switch/Switch';
import ImageUploader from '../../../../../sharedPacakge/components/ImageUploader/ImageUploader';
import CategoryDropdown from '../../../../../sharedPacakge/components/CategoryDropdown.tsx/CategoryDropdown';
import { MuiLocalizationAndThemeProvider } from '../../../../../sharedPacakge/components/MuiLocalizationAndThenmeProvider/MuiLocalizationAndThemeProvider';
import { AutocompleteInput } from '../../../../../components/AutoCompleteInput/AutoCompleteInput';

interface Props {
  setIsOpen: SetState<boolean>;
  uploadedImage: File;
}

const NewFoundItemModal: FC<Props> = ({ setIsOpen, uploadedImage }) => {
  const { showSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { organisation, location, itemCategories } = useLocationData();
  const [useImageDetection, setUseImageDetection] = useState<boolean>(
    getBooleanCookie(CookieName.EnabledFoundItemCreationImageDetection) ?? true
  );
  const [loadingImageDetection, setLoadingImageDetection] =
    useState<boolean>(false);
  const [imageFile, setImageFile] = useState<File>(uploadedImage);
  const [category, setCategory] = useState<ItemCategory | null>(null);
  const [description, setDescription] = useState('');
  const [foundAtDateTime, setFoundAtDateTime] = useState<Date>(new Date());
  const [disableSubmitButton, setDisableSubmitButton] =
    useState<boolean>(false);
  const [isHighValueItem, setIsHighValueItem] = useState<boolean>(false);
  //optional fields
  const [showOptionalFields, setShowOptionsFields] = useState<boolean>(false);
  const [storageLocation, setStorageLocation] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [foundAtLocation, setFoundAtLocation] = useState<string>('');
  const triggerEvent = useEventDispatcher();

  useEffect(() => {
    setCookie(
      CookieName.EnabledFoundItemCreationImageDetection,
      useImageDetection,
      Infinity
    );
  }, [useImageDetection]);

  useEffect(() => {
    if (!useImageDetection) {
      if (description === '' || !category) {
        setDisableSubmitButton(true);
      } else {
        setDisableSubmitButton(false);
      }
      return;
    }

    setDisableSubmitButton(false);
  }, [useImageDetection, description, category, imageFile]);

  if (!organisation || !location) return null;

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!location || !user) return;

    const formData = new FormData();
    formData.append('image', imageFile!);

    if (useImageDetection) {
      const postFoundItemPayload: PostFoundItemPayloadData = {
        imageDetection: true,
        posted_by_user_id: user.id,
        location_id: location.id,
        storage_location: storageLocation,
        notes,
        found_at_location: foundAtLocation,
        found_at_date: foundAtDateTime.toISOString()
      };

      formData.append('data', JSON.stringify(postFoundItemPayload));
    } else {
      if (!category) return showSnackbar('Kategori mangler', 'error');
      if (!description) return showSnackbar('Beskrivelse mangler', 'error');

      const postFoundItemPayload: PostFoundItemPayloadData = {
        imageDetection: false,
        category_id: category.id,
        description: description,
        posted_by_user_id: user.id,
        is_high_value_item: isHighValueItem,
        location_id: location.id,
        storage_location: storageLocation,
        notes,
        found_at_location: foundAtLocation,
        found_at_date: foundAtDateTime.toISOString()
      };
      formData.append('data', JSON.stringify(postFoundItemPayload));
    }

    // doing this, such the posting of the found item happens in the events context & a user can change page & still get the snackbar
    triggerEvent('foundItemUploaded', async () => {
      try {
        const foundItem = await FindyApi.post('found-item', formData);
        showSnackbar(`Genstand tilføjet`);
        return foundItem;
      } catch (e) {
        showSnackbar(`Der skete en fejl. genstand blev ikke oprettet`, 'error');
      }
    });

    setIsOpen(false);
  };

  const onFileUpload = (file: File) => {
    setLoadingImageDetection(true);
    scaleAndMinifyImage(file, 800, 800, 0.5)
      .then((optimzedImage) => {
        setImageFile(optimzedImage);
        setLoadingImageDetection(false);
      })
      .catch(() => {
        setLoadingImageDetection(false);
      });
  };

  const onNameChange = (e: any) => {
    setDescription(e.target.value);
  };

  const handleCategoryChange = (_event: any, newValue: ItemCategory | null) => {
    setCategory(newValue);
    if (newValue) setIsHighValueItem(newValue.is_high_value);
  };
  const onDatetimeChange = (value: any) => {
    setFoundAtDateTime(value.$d);
  };
  const onShowOptionalFieldsClick = (e: any) => {
    e.preventDefault();
    setShowOptionsFields((prevState) => !prevState);
  };

  return (
    <FormModal
      onFormSubmit={onSubmit}
      title="Tilføj fundet genstand"
      setIsOpen={setIsOpen}
      submitButtonText="Udfør"
      disabled={disableSubmitButton}
    >
      <div className={styles.container}>
        <Switch
          uniqueId={'useImageDetection-switch'}
          state={useImageDetection}
          label="Benyt billedegenkendelse"
          onChange={() => setUseImageDetection((prevState) => !prevState)}
        />
        <ImageUploader
          onFileUpload={onFileUpload}
          previewImage={imageFile}
          renderText={(image) => (
            <div style={{ padding: 7 }}>
              <img
                src={image}
                alt="Preview"
                style={{
                  width: '100%',
                  height: 'fit-content',
                  maxHeight: '200px',
                  objectFit: 'contain'
                }}
                width={600}
                height={200}
              />
            </div>
          )}
        />
        {!useImageDetection && (
          <>
            <CategoryDropdown
              value={category}
              itemCategories={itemCategories}
              onChange={handleCategoryChange}
              disabled={loadingImageDetection}
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Beskrivelse"
              variant="outlined"
              value={description}
              onChange={onNameChange}
              disabled={loadingImageDetection}
            />
            <Switch
              uniqueId={'isHighValueFoundItem-switch'}
              state={isHighValueItem}
              label="Genstand af værdi over 500kr."
              onChange={() => setIsHighValueItem((prevState) => !prevState)}
            />
          </>
        )}

        <div
          className={styles.moreFields__button}
          onClick={onShowOptionalFieldsClick}
        >
          Vis flere felter{' '}
          {showOptionalFields ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}{' '}
        </div>
        {showOptionalFields && (
          <div>
            <div className={`${styles.container} ${styles.split__container}`}>
              <MuiLocalizationAndThemeProvider>
                <DatePicker
                  onChange={onDatetimeChange}
                  value={dayjs(foundAtDateTime)}
                  maxDate={dayjs(new Date())}
                  disabled={loadingImageDetection}
                />
                <DesktopTimePicker
                  onAccept={onDatetimeChange}
                  value={dayjs(foundAtDateTime)}
                  maxTime={
                    isToday(foundAtDateTime) ? dayjs(new Date()) : undefined
                  }
                  disabled={loadingImageDetection}
                />
              </MuiLocalizationAndThemeProvider>
            </div>
            <div className={`${styles.container} ${styles.split__container}`}>
              <AutocompleteInput
                label="Fundet ved (Valgfri)"
                options={location.found_at_location_options}
                value={foundAtLocation}
                onChange={(newState) => setFoundAtLocation(newState)}
              />
              <AutocompleteInput
                label="Opbevaringssted (Valgfri)"
                options={location.storage_location_options}
                value={storageLocation}
                onChange={(newState) => setStorageLocation(newState)}
              />
            </div>
            <div className={styles.container}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Noter (Valgfri)"
                variant="outlined"
                value={notes}
                onChange={(e: any) => setNotes(e.target.value)}
                multiline
              />
            </div>
          </div>
        )}
      </div>
    </FormModal>
  );
};

export default NewFoundItemModal;
