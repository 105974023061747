import { ItemStatus } from '../../sharedPacakge/types/ItemStatus';

export const translateItemStatus = (itemStatus: ItemStatus | string) => {
  switch (itemStatus) {
    case ItemStatus.AwaitingMatch:
      return 'Afventer match';
    case ItemStatus.ReadyForPickup:
      return 'Afventer afhentning';
    case ItemStatus.LeftBehind:
      return 'Efterladt match';
    case ItemStatus.Expired:
      return 'Udløbet';
  }
};
