import React, { FC, useState } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import NewFoundItemModal from './newFoundItemModal';
import { scaleAndMinifyImage } from '../../../../../sharedPacakge/utils/imageScaleMinify';
import ImageUploader from '../../../../../sharedPacakge/components/ImageUploader/ImageUploader';
import { Button } from '../../../../../sharedPacakge/components/Button/Button';

interface Props {
  className?: string;
  classes?: {
    button: string;
  };
}

const NewFoundItemButton: FC<Props> = ({ className, classes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null); // Initialize with null

  const onClick = (e: any) => {
    e.preventDefault();
    setIsOpen(false);
  };

  const onFileUpload = (file: File) => {
    scaleAndMinifyImage(file, 800, 800, 0.5)
      .then((optimzedImage) => {
        setUploadedImage(optimzedImage);
        setIsOpen(true);
      })
      .catch(() => {});
  };

  return (
    <>
      <ImageUploader
        onFileUpload={onFileUpload}
        resetStateOnUpload
        className={className ?? ''}
        styles={{
          border: 'none'
        }}
        renderText={(image) => (
          <div>
            <Button className={classes?.button} onClick={onClick} fullWidth>
              <AddOutlinedIcon />
              Ny genstand
            </Button>
          </div>
        )}
      />

      {isOpen && uploadedImage && (
        <NewFoundItemModal
          setIsOpen={setIsOpen}
          uploadedImage={uploadedImage}
        />
      )}
    </>
  );
};

export default NewFoundItemButton;
