import { supabase } from '../../../supabase/supabaseClient';
import {
  ArchivedFoundItem,
  ArchivedFoundItemRecord,
  FoundItemRecord,
  FoundItem,
  ItemCategory
} from '../../../../types/supabase/collections';
import type { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { Database } from '../../../../types/supabase/supabaseDB';
import logger from '../../../../sharedPacakge/logger';

export const foundItemFields = `
      id,
      description,
      expiry_date,
      found_at_date,
      image,
      is_high_value_item,
      location_id,
      organisation_id,
      posted_by_user_id,
      status,
      created_at,
      updated_at,
      notes,
      found_at_location,
      storage_location,
      categories (
        id,
        name,
        name_danish
      )`;

type CustomQuery<Archived extends boolean> = PostgrestFilterBuilder<
  Database['public'],
  Archived extends true ? ArchivedFoundItemRecord : FoundItemRecord,
  any
>;

export async function getFoundItems<Archived extends boolean = false>(args: {
  location_id: number;
  archived?: Archived;
  customQuery?: (query: CustomQuery<Archived>) => CustomQuery<Archived>;
}): Promise<
  (Archived extends true ? ArchivedFoundItem[] : FoundItem[]) | null
> {
  const { location_id, archived, customQuery } = args;

  // Start with the base query
  let query = archived
    ? supabase
        .from('archived_found_items')
        .select(`${foundItemFields}, archive_reason, archived_date`)
        .eq('location_id', location_id)
    : supabase
        .from('found_items')
        .select(foundItemFields)
        .eq('location_id', location_id);

  if (customQuery) {
    query = customQuery(query); // Apply the query builder function
  }

  // Apply order by clause
  query = query.order('found_at_date', { ascending: false });

  const { data, error } = await query;

  if (error) {
    logger.error(
      `getFoundItems error fetching found items: ${JSON.stringify(error)}`
    );
    throw error;
  }

  return mapToFoundItems<Archived>(data as any);
}

export function mapToFoundItems<Archived extends boolean>(
  items: Array<
    Omit<
      Archived extends true ? ArchivedFoundItemRecord : FoundItemRecord,
      'category_id'
    > & {
      categories: ItemCategory | null;
    }
  >
): Archived extends true ? ArchivedFoundItem[] : FoundItem[] {
  return items.map((item) => {
    return {
      ...item,
      category: item.categories
    };
  }) as any;
}
