import { Database, Tables } from '../../types/supabase/supabaseDB';
import { supabase } from './supabaseClient';
import logger from '../../sharedPacakge/logger';

type TableRecords<T> = T extends { Tables: { [key: string]: { Row: infer R } } }
  ? { [K in keyof T['Tables']]: T['Tables'][K]['Row'] }
  : never;

type PublicTableRecords = TableRecords<Database['public']>;

type TableNameToRecordMap = PublicTableRecords;

export async function getRecords<T extends keyof TableNameToRecordMap>(
  tableName: T,
  ids: number[]
): Promise<Tables<T>[] | null> {
  const { data, error } = await supabase
    .from(tableName)
    .select('*')
    .in('id', ids);

  if (error) {
    logger.supabaseError('getRecords error', error);
    throw error;
  }

  return data as any[];
}

export async function deleteRecords<T extends keyof TableNameToRecordMap>(
  tableName: T,
  ids: number[]
): Promise<void> {
  const { error } = await supabase.from(tableName).delete().in('id', ids);

  if (error) {
    logger.supabaseError('deleteRecords error', error);
    throw error;
  }
}

type InsertType<T extends keyof TableNameToRecordMap> =
  Database['public']['Tables'][T]['Insert'];

export async function createRecord<T extends keyof TableNameToRecordMap>(
  tableName: T,
  record: InsertType<T>
): Promise<TableNameToRecordMap[T] | null> {
  // Ensure the record is wrapped in an array for insertion
  const { data, error } = await supabase
    .from(tableName as keyof PublicTableRecords) // casting tableName as string
    .insert([record]) // insert expects an array of records
    .single(); // .single() expects exactly one record to be returned

  if (error) {
    logger.supabaseError('createRecord error', error);
    throw error;
  }

  return data as TableNameToRecordMap[T];
}

// type RecordType<T extends keyof TableNameToRecordMap> = TableNameToRecordMap[T];
//
// type InsertType<T extends keyof TableNameToRecordMap> =
//   Database['public']['Tables'][T]['Insert'];

// export async function getRecord<T extends keyof TableNameToRecordMap>(
//   tableName: T,
//   id: number
// ): Promise<RecordType<T> | null> {
//   const { data, error: getRecordError } = await supabase
//     .from(tableName)
//     .select('*')
//     .eq('id', id)
//     .single();
//
//   if (getRecordError) {
//     console.error('getRecord', getRecordError);
//     return null;
//   }
//
//   return data as unknown as RecordType<T>;
// }

// export async function deleteRecord<T extends keyof TableNameToRecordMap>(
//   tableName: T,
//   id: number
// ): Promise<void> {
//   const { error } = await supabase.from(tableName).delete().eq('id', id);
//
//   if (error) {
//     console.error('deleteRecord', error);
//   }
// }
