import { encodeIntId } from '../sharedPacakge/utils/IntIdEncryption';

class Page {
  static createOrganisation = '/create-organisation';
  static login = '/login';
  static signup = '/signup';
  static resetPassword = '/reset-password';
  static confirmResetPassword = '/confirm-reset-password';

  static l = class {
    static actions(locationId: number) {
      return `/l/${encodeIntId(locationId)}/actions`;
    }

    static matchmaking(locationId: number, foundItemId: number) {
      return `/l/${encodeIntId(locationId)}/actions/matchmaking/${encodeIntId(foundItemId)}`;
    }

    static foundItems(locationId: number) {
      return `/l/${encodeIntId(locationId)}/found-items`;
    }
    static inquiries(locationId: number) {
      return `/l/${encodeIntId(locationId)}/inquiries`;
    }

    static customization(locationId: number) {
      return `/l/${encodeIntId(locationId)}/customization`;
    }
    static editInquiryForm(locationId: number) {
      return `/l/${encodeIntId(locationId)}/edit-inquiry-form`;
    }
    static emailTemplates(locationId: number) {
      return `/l/${encodeIntId(locationId)}/email-templates`;
    }

    static users(locationId: number) {
      return `/l/${encodeIntId(locationId)}/users`;
    }
    static feedback(locationId: number) {
      return `/l/${encodeIntId(locationId)}/feedback`;
    }
  };

  static external = class {
    static lostSomething(organisationId: number, organisationName: string) {
      return `https://lost.findy.dk/da/${encodeIntId(organisationId)}/${encodeURIComponent(organisationName)}`;
    }
    static lostSomethingIframe(
      organisationId: number,
      organisationName: string
    ) {
      return `https://lost.findy.dk/da/${encodeIntId(organisationId)}/${encodeURIComponent(organisationName)}?iframe=true`;
    }
  };
}

export default Page;

// Utility type to extract return types from functions
export type ExtractRouteStrings<T> = T extends (...args: any[]) => infer R
  ? R
  : never;

// Recursively extract return types from an object type
export type ExtractRoutes<T> = T extends { [key: string]: any }
  ? {
      [K in keyof T]: T[K] extends (...args: any[]) => any
        ? ExtractRouteStrings<T[K]>
        : ExtractRoutes<T[K]>;
    }[keyof T]
  : never;

export type PageType = ExtractRoutes<typeof Page>;
