import React, { useState } from 'react';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import { TabLayout } from '../../components/TabLayout/TabLayout';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import Api from '../../../../servieces/Api/Api';
import { useAuth } from '../../../../contexts/AuthContext/AuthContext';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from '../../../../sharedPacakge/components/Button/Button';
import { useSnackbar } from '../../../../sharedPacakge/components/Snackbar/SnackbarContext';

export type FeedbackType =
  | 'Feedback'
  | 'Fejl'
  | 'Forslag'
  | 'Spørgsmål'
  | 'Andet';

const feedbackTypes: FeedbackType[] = [
  'Feedback',
  'Fejl',
  'Forslag',
  'Spørgsmål',
  'Andet'
];

export const FeedbackTab = () => {
  const { user } = useAuth();
  const { organisation, location } = useLocationData();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [feedbackType, setFeedbackType] = useState<FeedbackType>('Feedback');
  const [message, setMessage] = useState<string>('');
  const [isSubmittet, setIsSubmittet] = useState<boolean>(false);

  if (!organisation || !location || !user) return null;

  const onSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if (message === '') return showSnackbar('ingen besked');

    Api.post
      .feedback({
        feedback_type: feedbackType,
        organisation_id: organisation.id,
        user_id: user.id,
        message
      })
      .then(() => {
        setIsLoading(false);
        showSnackbar('Feedback er modtaget');
        setIsSubmittet(true);
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'));
  };

  return (
    <TabLayout>
      {isSubmittet ? (
        <div
          style={{
            display: 'flex',
            gap: 10,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 40
          }}
        >
          <h2 style={{ color: '#2D7CFE' }}>Feedback modtaget</h2> <CheckIcon />
        </div>
      ) : (
        <form
          onSubmit={onSubmit}
          style={{
            maxWidth: 600,
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            marginTop: 40
          }}
        >
          <FormControl fullWidth>
            <InputLabel>Feedback Type</InputLabel>{' '}
            {/* Add InputLabel for the Select */}
            <Select
              label="Feedback Type"
              disabled={isLoading}
              value={feedbackType}
              onChange={(e: any) => setFeedbackType(e.target.value)}
            >
              {feedbackTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Besked"
            value={message}
            onChange={(e: any) => setMessage(e.target.value)}
            fullWidth
            variant="outlined"
            disabled={isLoading}
            multiline
            rows={4}
          />
          <Button type="submit" fullWidth>
            Send
          </Button>
        </form>
      )}
    </TabLayout>
  );
};
