import React, { FC, useEffect, useState } from 'react';
import styles from './inquiriesTable.module.css';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../servieces/Api/Api';
import { TabLayout } from '../../components/TabLayout/TabLayout';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {
  ArchivedInquiry,
  Inquiry
} from '../../../../types/supabase/collections';
import GenericTable, {
  Column
} from '../../../../components/GenericTable/GenericTable';
import { formatDate } from '../../../../utils/formatting/formatDate';
import ViewInquiryModal from './ViewInquiryModal/ViewInquiryModal';
import useFilters from '../../hooks/useFilters';
import { LoadingSpinner } from '../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import { translateItemStatus } from '../../../../utils/translate/translateItemStatus';
import { Switch } from '../../../../sharedPacakge/components/Switch/Switch';
import { translateArchiveReason } from '../../../../utils/translate/translateArchiveReason';
import { FoundItemArchiveReason } from '../../../../sharedPacakge/types';
import { AutocompleteInput } from '../../../../components/AutoCompleteInput/AutoCompleteInput';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const InquiriesTable: FC = () => {
  const { location } = useLocationData();
  const [filters, udpateFilter] = useFilters();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [inquiries, setInquiries] = useState<Inquiry[] | ArchivedInquiry[]>([]);
  const [viewingItemModalOpen, setViewingItemModalOpen] = useState(false);
  const [viewingItem, setViewingItem] = useState<
    Inquiry | ArchivedInquiry | null
  >(null);

  const removeInquiryLocally = (id: number) => {
    setInquiries((prevState) => prevState.filter((item) => item.id !== id));
  };

  useEffect(() => {
    if (!location) return;

    Api.get
      .inquiries({
        location_id: location.id,
        archived: filters.isArchived
      })
      .then(
        (foundItemsResponse) =>
          foundItemsResponse && setInquiries(foundItemsResponse)
      );
    setIsLoading(false);
  }, [location, filters.isArchived]);

  if (isLoading)
    return (
      <TabLayout>
        <div className={styles.loadingSpinner__container}>
          <LoadingSpinner />
        </div>
      </TabLayout>
    );

  const columns: Column<Inquiry | ArchivedInquiry>[] = [
    {
      id: 'image',
      label: 'Billede',
      render: ({ image }) =>
        image ? (
          <img
            className={styles.itemImage}
            src={image}
            alt="genstand billede"
            width={90}
            height={90}
          />
        ) : (
          '(intet billede)'
        )
    },
    {
      id: 'category',
      label: 'Kategori',
      render: ({ category }) => category.name_danish
    },
    {
      id: 'description',
      label: 'Beskrivelse',
      render: ({ description }) => (
        <div className={styles.description}>{description}</div>
      )
    },
    {
      id: 'lostAtDateTime',
      label: 'Tabt tidspunkt',
      render: ({ lost_at_date }) => formatDate(lost_at_date)
    },
    {
      id: 'name',
      label: 'Navn',
      render: ({ reporter_name }) => reporter_name
    },

    {
      id: 'id',
      label: 'ID',
      render: ({ id }) => id
    }
  ];

  const isArchivedItem = (item: Inquiry | ArchivedInquiry) =>
    'archive_reason' in item;

  if (inquiries.length > 0 && !isArchivedItem(inquiries[0])) {
    columns.push({
      id: 'status',
      label: 'Status',
      render: ({ status }) => translateItemStatus(status)
    });
  }
  if (inquiries.length > 0 && isArchivedItem(inquiries[0])) {
    columns.push({
      id: 'archive_reason',
      label: 'Arkiveringsårsag',
      render: (item) =>
        'archive_reason' in item
          ? translateArchiveReason(
              item.archive_reason as FoundItemArchiveReason
            )
          : ''
    });
  }
  const onRowClick = (e: any, item: Inquiry) => {
    e.preventDefault();
    setViewingItem(item as Inquiry);
    setViewingItemModalOpen(true);
  };

  const queryFilter = (item: Inquiry) =>
    filters.query
      ? item.category.name_danish
          .toLowerCase()
          .includes(filters.query.toLowerCase()) ||
        item.description.toLowerCase().includes(filters.query.toLowerCase())
      : true;

  const filteredInquiries = filters.query
    ? inquiries.filter((item) => queryFilter(item))
    : inquiries;

  const uniqueQueryFilterOptions = Array.from(
    new Set([
      ...filteredInquiries.map((item) => item.description),
      ...filteredInquiries.map((item) => item.category.name_danish)
    ])
  );

  return (
    <TabLayout>
      <div className={styles.container}>
        <div className={styles.filters__container}>
          <div className={styles.searchBar__container}>
            <AutocompleteInput
              label="Søg"
              placeholder="Kategori eller beskrivelse"
              icon={<SearchOutlinedIcon sx={{ color: '#C4C4C4 !important' }} />}
              options={uniqueQueryFilterOptions}
              value={filters.query || ''}
              onChange={(newValue) => udpateFilter({ query: newValue })}
              maxOptions={7}
            />
          </div>
          <div>
            <Switch
              uniqueId="inquiry-archive-switch"
              state={!!filters.isArchived}
              label="Vis afviklede genstande"
              onChange={() => udpateFilter({ isArchived: !filters.isArchived })}
            />
          </div>
        </div>
        {!isLoading && inquiries.length === 0 ? (
          <>
            <h2 className={styles.noItems__text}>
              {filters.isArchived
                ? 'Ingen afviklet efterlysninger endnu'
                : 'Ingen efterlysninger endnu'}
            </h2>
            <p
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px'
              }}
            >
              Lær hvordan du kan modtage efterlysninger, ved at klikke på
              <HelpOutlineOutlinedIcon />
            </p>
          </>
        ) : (
          <>
            <GenericTable<Inquiry | ArchivedInquiry>
              columns={columns}
              data={filteredInquiries}
              rowKey="id"
              onRowClick={onRowClick}
            />
            {viewingItemModalOpen && viewingItem && (
              <ViewInquiryModal
                callbacks={{
                  onArchiveCallback: removeInquiryLocally,
                  onUnArchiveCallback: removeInquiryLocally
                }}
                inquiry={viewingItem}
                setIsOpen={setViewingItemModalOpen}
              />
            )}
          </>
        )}
      </div>
    </TabLayout>
  );
};

export default InquiriesTable;
