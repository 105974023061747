import styles from './sidebar.module.css';
import React, { FC } from 'react';
import { Divider } from '@mui/material';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { tabsConfiguration } from './tabsConfiguration';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageType } from '../../../Page';
import NewFoundItemButton from './NewFoundItemButton/NewFoundItemButton';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import { useIsMobile } from '../../../../utils/hooks/useIsMobile';
import InfoButton from '../Header/InfoModal/InfoButton';
interface Props {
  closeNavbarMobile?: () => void;
}
export const Sidebar: FC<Props> = ({ closeNavbarMobile }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const { organisation, location , userRole} = useLocationData();

  if (!organisation || !location) return null;
  
  const allTabs = tabsConfiguration(location.id);
  const tabs = allTabs.filter(tab => tab.visibleForRoles ?  tab.visibleForRoles.includes(userRole) : true) // only tabs user has correct role for

  const onTabClick = (e: any, page: PageType) => {
    e.preventDefault();
    navigate(page);
    closeNavbarMobile && closeNavbarMobile();
  };

  return (
    <div className={styles.container}>
      <div>
        {!isMobile && (
          <NewFoundItemButton
            className={styles.foundItem__button}
            classes={{ button: styles.newFoundItemButton }}
          />
        )}

        <ul className={styles.tabs__list}>
          {tabs.map((tab, index) => {
            const isSelected = pathname.startsWith(tab.urlPattern); // this includes subTabs
            return (
              <React.Fragment key={index}>
                <li
                  onClick={(e) => onTabClick(e, tab.route)}
                  className={`${styles.tabs__listItem} ${isSelected ? styles.selected : ''}`}
                >
                  {tab.icon}
                  <span>{tab.name}</span>
                </li>
                {tab?.dividerAfter && <Divider className={styles.divider} />}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
      <div>
        {isMobile && <InfoButton />}
        <Divider className={styles.divider} />
        <div className={styles.organisationInfo__container}>
          <BusinessOutlinedIcon /> <span>{organisation?.name}</span>
        </div>
      </div>
    </div>
  );
};
