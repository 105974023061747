import React, { useState } from 'react';
import Api from '../../../../../servieces/Api/Api';
import {
  ArchivedFoundItem,
  FoundItem
} from '../../../../../types/supabase/collections';
import EditIcon from '@mui/icons-material/Edit';
import { TextField } from '@mui/material';
import { Button } from '../../../../../sharedPacakge/components/Button/Button';

interface Props<T> {
  foundItem: T;
  updateFoundItem: (id: number, newValue: T) => void;
  field: 'notes' | 'description' | 'storage_location';
}

export function EditableFoundItemTextField<
  T extends FoundItem | ArchivedFoundItem
>({ field, updateFoundItem, foundItem }: Props<T>) {
  const actualValue =
    foundItem[field] === '' ? 'ikke specificeret' : foundItem[field];
  const [value, setValue] = useState<string>(foundItem[field]);
  const [isEdeting, setIsEdeting] = useState<boolean>(false);
  const onSaveClick = (e: any) => {
    e.preventDefault();

    Api.put.foundItem(foundItem.id, {
      [field]: value
    });
    updateFoundItem(foundItem.id, {
      ...foundItem,
      [field]: value
    });
    setIsEdeting(false);
  };

  const onEditClick = (e: any) => {
    e.preventDefault();
    setIsEdeting(true);
  };

  const onCancelClick = (e: any) => {
    e.preventDefault();
    setIsEdeting(false);
  };
  return isEdeting ? (
    <form onSubmit={onSaveClick}>
      <TextField
        type="text"
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
        required={true}
        fullWidth
        variant="outlined"
        multiline
      />
      <div style={{ display: 'flex', marginTop: 5, gap: 5 }}>
        <Button type="submit">Gem</Button>
        <Button onClick={onCancelClick} buttonType="secondary">
          annuler
        </Button>
      </div>
    </form>
  ) : (
    <>
      {actualValue}{' '}
      <EditIcon
        style={{ fontSize: 14, cursor: 'pointer' }}
        onClick={onEditClick}
      />
    </>
  );
}
