import React, { useState } from 'react';
import styles from '../loginAndSignupStyles.module.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import logo from '../../../assets/logo/darkFindyLogo.png';
import { supabase } from '../../../servieces/supabase/supabaseClient';
import Page from '../../Page';
import { Button } from '../../../sharedPacakge/components/Button/Button';

export const doPasswordReset = async (newPassword: string): Promise<any> => {
  try {
    await supabase.auth.updateUser({ password: newPassword });
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};

export const ConfirmResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate(); // Use navigate instead of redirect

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage('');

    if (password.length < 8) {
      setErrorMessage('Password skal mindst være 8 karakter');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Password matcher ikke');
      return;
    }

    setIsLoading(true);
    try {
      await doPasswordReset(password);
      setTimeout(() => {
        navigate(Page.login);
      }, 2500);
    } catch {
      setErrorMessage('Der opstod en fejl');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className={styles.container}>
      <img
        className={styles.logo}
        src={logo}
        alt="findy-logo"
        width={100}
        height={70}
      />

      <form className={styles.form} onSubmit={onSubmit}>
        <>
          <h1 className={styles.title}>Ny adgangskode</h1>
          <TextField
            label="Adgangskode"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            variant="outlined"
            disabled={isLoading}
          />
          <TextField
            label="Bekræft adgangskode"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            variant="outlined"
            disabled={isLoading}
          />
          {errorMessage && (
            <p className={styles.errorMessage}>{errorMessage}</p>
          )}
          <Button disabled={isLoading} type="submit" fullWidth>
            Opdater adgangskode
          </Button>
          <p className={styles.noUser__text}>
            Tilbage til{' '}
            <Link className={styles.noUser__link} to={'/login'}>
              Login
            </Link>
          </p>
        </>
      </form>
    </main>
  );
};
