import { supabase } from '../../servieces/supabase/supabaseClient';
import logger from '../logger';
import { formatDate } from '../../utils/formatting/formatDate';

const findyApiEndpoint =
  process.env.REACT_APP_FINDY_API_ENDPOINT ??
  process.env.NEXT_PUBLIC_FINDY_API_ENDPOINT;

const stringifyFormData = (formData: FormData): string => {
  const data: Record<string, string> = {};
  formData.forEach((value, key) => {
    if (value instanceof Blob) {
      data[key] =
        `Blob(File: ${value instanceof File ? value.name : 'unknown'}, ${value.size} bytes)`;
    } else {
      data[key] = value.toString();
    }
  });
  return JSON.stringify(data, null, 2); // Pretty print with indentation
};

class FindyApi {
  private static async getAuthHeader(): Promise<HeadersInit> {
    const { data, error } = await supabase.auth.getSession();
    if (error) {
      logger.error(
        `FindyApi getAuthHeader Failed to get user session: ${error}`
      );
      return {};
    }

    let session = data.session;
    if (!session) {
      logger.error(`FindyApi getAuthHeader Failed: No session found`);
      return {};
    }

    if (session.expires_at) {
      const tokenExpiry = new Date(session.expires_at * 1000); // Convert seconds to milliseconds
      const now = new Date();
      const refreshThreshold = 5 * 60 * 1000; // 5 minutes in milliseconds

      if (tokenExpiry.getTime() - now.getTime() < refreshThreshold) {
        const { error: refreshError } = await supabase.auth.refreshSession();
        if (refreshError) {
          logger.error(
            `FindyApi getAuthHeader Failed to refresh session: ${refreshError}`
          );
          return {};
        }

        // Get the updated session data
        const { data: refreshedData } = await supabase.auth.getSession();
        if (!refreshedData.session) {
          logger.error(
            'FindyApi getAuthHeader failed to refresh session: ${refreshError}'
          );

          return {};
        }

        session = refreshedData.session;
      }
    }
    return {
      Authorization: `Bearer ${session.access_token}`
    };
  }

  // Handle request with type-checking headers
  static async request(
    endpoint: string,
    method: string,
    body: object | FormData | null = null,
    skipAuthHeader: boolean = false,
    headers: HeadersInit = {}
  ) {
    const authHeader = skipAuthHeader ? {} : await this.getAuthHeader();

    const options: RequestInit = {
      method,
      headers: {
        ...authHeader,
        ...headers
      },
      credentials: 'include'
    };

    if (body) {
      if (body instanceof FormData) {
        options.body = body;
        // Do not set Content-Type header; FormData will handle it
        if (options.headers && 'Content-Type' in options.headers) {
          delete options.headers['Content-Type'];
        }
      } else {
        options.body = JSON.stringify(body);
        if (options.headers && 'Content-Type' in options.headers) {
          options.headers['Content-Type'] = 'application/json';
        }
      }
    }
    logger.debug(
      `fetchFindyAPi before the try-catch ${JSON.stringify(body)} ${body}`
    );

    try {
      const response = await fetch(`${findyApiEndpoint}/${endpoint}`, options);

      if (!response.ok) {
        logger.error(
          `fetchFindyApi ${method} request to ${endpoint} failed: ${response.statusText}`
        );
        throw new Error(
          `${method} request to ${endpoint} failed: ${response.statusText}`
        );
      }

      if (method === 'DELETE') {
        const data = await response.json();
        return data.success;
      }
      logger.debug(`fetchFindyAPi before returning the response`);

      return await response.json();
    } catch (error) {
      logger.debug(`fetchFindyAPi error ${error}`);
      logger.error(
        `fetchFindyApi error during ${method} request to ${endpoint}
        ERROR: ${JSON.stringify(error)}
        BODY: ${body instanceof FormData ? stringifyFormData(body) : JSON.stringify(body)}
        `
      );
      logger.debug(`fetchFindyAPi before thorwing the error`);
      throw error;
    }
  }

  static get(endpoint: string) {
    return this.request(endpoint, 'GET');
  }

  static post(endpoint: string, body: object | FormData | null = null) {
    return this.request(endpoint, 'POST', body);
  }

  static put(endpoint: string, body: object) {
    return this.request(endpoint, 'PUT', body);
  }

  static delete(endpoint: string) {
    return this.request(endpoint, 'DELETE');
  }
}

export default FindyApi;
