import styles from '../../actionsTab.module.css';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import FoundItemsTable from '../../../../components/FoundItemsTable/FoundItemTable';
import React, { useEffect, useState } from 'react';
import { TabLayout } from '../../../../components/TabLayout/TabLayout';
import {
  ArchivedFoundItem,
  FoundItem,
  MatchRecord
} from '../../../../../../types/supabase/collections';
import Page from '../../../../../Page';
import { useLocationData } from '../../../../../../contexts/LocationDataContext/LocationDataContext';
import { useNavigate } from 'react-router-dom';
import Api from '../../../../../../servieces/Api/Api';
import { useEventListener } from '../../../../../../contexts/EventsProvider.ts/EventsProvider';
import { ItemStatus } from '../../../../../../sharedPacakge/types/ItemStatus';
import { LoadingSpinner } from '../../../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';

export const PossibleMatchesTable = () => {
  const { location } = useLocationData();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [possibleMatches, setPossibleMatches] = useState<FoundItem[]>([]);
  const [matchesData, setMatchesData] = useState<MatchRecord[]>([]);

  const fetchFoundItems = () => {
    if (!location) return;
    setIsLoading(true);
    Api.get
      .matches({
        customQuery: (query) =>
          query
            .eq('location_id', location.id)
            .eq('match_rejected', false)
            .eq('match_confirmed', false)
      })
      .then((possibleMatchesResponse) => {
        setMatchesData(possibleMatchesResponse);
        const uniqueFoundItemIds = Array.from(
          new Set(possibleMatchesResponse.map((match) => match.found_item_id))
        );

        Api.get
          .foundItems({
            location_id: location.id,
            customQuery: (query) =>
              query
                .in('id', uniqueFoundItemIds)
                .eq('status', ItemStatus.AwaitingMatch)
          })
          .then((possibleMatchesResponse) => {
            possibleMatchesResponse &&
              setPossibleMatches(possibleMatchesResponse);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEventListener('foundItemUploaded', fetchFoundItems);

  useEffect(() => {
    fetchFoundItems();
  }, [location]);

  if (!location) return null;

  if (isLoading)
    return (
      <TabLayout>
        <div>
          <LoadingSpinner />
        </div>
      </TabLayout>
    );

  const onSeeMatchesClick = (e: any, foundItem: FoundItem) => {
    e.preventDefault();
    navigate(Page.l.matchmaking(location.id, foundItem.id));
  };

  const getMatchCount = (foundItemId: number) =>
    matchesData.filter((match) => match.found_item_id === foundItemId).length;

  const removeFoundItemLocally = (id: number) => {
    setPossibleMatches((prevState) =>
      prevState.filter((item) => item.id !== id)
    );
  };

  const updateFoundItem = (
    id: number,
    newValue: FoundItem | ArchivedFoundItem
  ) => {
    setPossibleMatches((prevState) =>
      prevState.map((item) => (item.id === id ? newValue : item))
    );
  };

  return (
    <FoundItemsTable
      isLoading={isLoading}
      foundItems={possibleMatches}
      updateFoundItem={updateFoundItem}
      callbacks={{
        onArchiveCallback: removeFoundItemLocally
      }}
      options={{
        columns: ['image', 'category', 'description', 'found_at_date'],
        // onRowClick: onPossibleMatchRowClick,
        renderNoFoundItems: () => (
          <span className={styles.noFoundItems__text}>
            <InventoryRoundedIcon />
            <p>Du er helt ajour.</p>
          </span>
        ),
        renderActionsColumn: (row) => (
          <div className={styles.tableActions__container}>
            <Button onClick={(e) => onSeeMatchesClick(e, row)}>
              Se {getMatchCount(row.id)} matches
            </Button>
          </div>
        )
      }}
    />
  );
};
