/**
 * Returns a date that is a specified number of days from now.
 * @param {number} days - The number of days from now.
 * @returns {Date} The future date.
 */
export function getDateDaysFromNow(days: number) {
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + days);
  return futureDate;
}
