import styles from '../../actionsTab.module.css';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import FoundItemsTable from '../../../../components/FoundItemsTable/FoundItemTable';
import React, { useEffect, useState } from 'react';
import { TabLayout } from '../../../../components/TabLayout/TabLayout';
import {
  ArchivedFoundItem,
  FoundItem
} from '../../../../../../types/supabase/collections';
import { useLocationData } from '../../../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../../../servieces/Api/Api';
import { useEventListener } from '../../../../../../contexts/EventsProvider.ts/EventsProvider';
import { LoadingSpinner } from '../../../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import { ItemStatus } from '../../../../../../sharedPacakge/types/ItemStatus';
import { FoundItemArchiveReason } from '../../../../../../sharedPacakge/types';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import { archiveItems } from '../../../../../../servieces/Api/methods/put/archiveItems';

export const ExpiredOrLeftBehindTable = () => {
  const { location } = useLocationData();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [expiredOrLeftBehind, setExpiredOrLeftBehind] = useState<FoundItem[]>(
    []
  );
  const [selectedRows, setSelectedRows] = useState<FoundItem[]>([]);

  const fetchFoundItems = () => {
    if (!location) return;
    setIsLoading(true);
    Api.get
      .foundItems({
        location_id: location.id,
        customQuery: (query) =>
          query.in('status', [ItemStatus.LeftBehind, ItemStatus.Expired])
      })
      .then((expiredOrLeftBehindResponse) => {
        expiredOrLeftBehindResponse &&
          setExpiredOrLeftBehind(expiredOrLeftBehindResponse);

        setIsLoading(false);
      });
  };

  useEventListener('foundItemUploaded', fetchFoundItems);
  useEffect(() => {
    fetchFoundItems();
  }, [location]);

  if (!location) return null;

  if (isLoading)
    return (
      <TabLayout>
        <div>
          <LoadingSpinner />
        </div>
      </TabLayout>
    );

  const removeFoundItemLocally = (id: number) => {
    setExpiredOrLeftBehind((prevState) =>
      prevState.filter((item) => item.id !== id)
    );
  };

  const onArchiveItemsClick = async (
    e: any,
    reason: FoundItemArchiveReason
  ) => {
    e.preventDefault();

    await archiveItems({
      ids: selectedRows.map((row) => row.id),
      type: 'foundItems',
      archiveReason: reason
    });

    setExpiredOrLeftBehind((prevState) =>
      prevState.filter(
        (item) =>
          !selectedRows.some((selectedRow) => selectedRow.id === item.id)
      )
    );
    setSelectedRows([]);
  };

  const updateFoundItem = (
    id: number,
    newValue: FoundItem | ArchivedFoundItem
  ) => {
    setExpiredOrLeftBehind((prevState) =>
      prevState.map((item) => (item.id === id ? newValue : item))
    );
  };

  return (
    <>
      {selectedRows.length > 0 && (
        <div style={{ display: 'flex', gap: 15 }}>
          <Button onClick={(e) => onArchiveItemsClick(e, 'Handed to police')}>
            Afleveret til Politi
          </Button>
          <Button onClick={(e) => onArchiveItemsClick(e, 'Donated')}>
            Doneret
          </Button>
          <Button
            onClick={(e) => onArchiveItemsClick(e, 'Kept')}
            buttonType="secondary"
          >
            Beholdt selv
          </Button>
        </div>
      )}
      <FoundItemsTable
        isLoading={isLoading}
        foundItems={expiredOrLeftBehind}
        updateFoundItem={updateFoundItem}
        callbacks={{
          onArchiveCallback: removeFoundItemLocally
        }}
        options={{
          columns: ['image', 'category', 'description'],
          customColumns: [
            {
              id: 'expiryReason',
              label: 'Årsag',
              render: (row) =>
                row?.status === ItemStatus.LeftBehind
                  ? 'blev aldrig afhentet'
                  : 'blev aldrig matchet'
            }
          ],
          // onRowClick: onPossibleMatchRowClick,
          renderNoFoundItems: () => (
            <span className={styles.noFoundItems__text}>
              <InventoryRoundedIcon />
              <p>Du er helt ajour.</p>
            </span>
          ),
          multiSelect: {
            enabled: true,
            onSelectedRowChange: (rows) => setSelectedRows(rows)
          }
        }}
      />
    </>
  );
};
