import { supabase } from '../../../supabase/supabaseClient';
import {
  FoundItem,
  ItemCategory
} from '../../../../types/supabase/collections';
import { foundItemFields } from './getFoundItems';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function getFoundItem(id: number): Promise<FoundItem> {
  const { data, error } = await supabase
    .from('found_items')
    .select(foundItemFields)
    .eq('id', id)
    .single();

  assertNoSupabaseError(data, error, 'getFoundItem error:', {}, true);

  return {
    ...data,
    category: data.categories as ItemCategory
  };
}
