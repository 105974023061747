import React, { FC } from 'react';
import styles from './button.module.css';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean;
  buttonType?: 'primary' | 'secondary' | 'warn';
}

export const Button: FC<Props> = ({
  buttonType = 'primary',
  fullWidth,
  className,
  style,
  ...props
}) => {
  const buttonProps = {
    ...props,
    className: `${styles.button} ${className} ${buttonType === 'secondary' ? styles.secondary : ''} ${buttonType === 'warn' ? styles.warn : ''}`,
    style: { ...style, ...(fullWidth ? { width: '100%' } : {}) }
  };

  return <button {...buttonProps} />;
};
