import { Database, Tables } from './supabaseDB';

export type Organisation = Tables<'organisations'>;
export type Location = Tables<'locations'>;
export type LocationRecord = Tables<'locations'>;
export type UserRoleRecord = Tables<'user_roles'>;
export type emailTemplateRecord = Tables<'email_templates'>;
export type userProfileRecord = Tables<'user_profiles'>;
export type CategoryRecord = Tables<'categories'>;
export type ItemCategory = Tables<'categories'>;
export type FeedbackRecord = Tables<'feedback'>;
export type MatchRecord = Tables<'matches'>;
export type InquiryRecord = Tables<'inquiries'>;
export type ArchivedInquiryRecord = Tables<'archived_inquiries'>;
export type FoundItemRecord = Tables<'found_items'>;
export type ArchivedFoundItemRecord = Tables<'archived_found_items'>;
export type InquiryFormRecord = Tables<'inquiry_forms'>;

export type EmailType = Database['public']['Enums']['email_type'];
export const emailTypes: EmailType[] = [
  'INQUIRY_CREATED',
  'WE_ARE_STILL_SEARCHING',
  'WE_DIDNT_FIND_YOUR_ITEM',
  'ITEM_FOUND_READY_FOR_PICKUP',
  'ITEM_NEVER_PICKED_UP',
  'X_DAYS_LEFT_TO_PICKUP'
];

export enum Role {
  OrganisationAdmin = 'organisation_admin',
  LocationAdmin = 'location_admin'
}

export type Inquiry = Omit<InquiryRecord, 'category_id'> & {
  category: ItemCategory;
};

export type ArchivedInquiry = Omit<ArchivedInquiryRecord, 'category_id'> & {
  category: ItemCategory;
};

export type FoundItem = Omit<FoundItemRecord, 'category_id'> & {
  category: ItemCategory;
  // potential_matches: PotentialMatch[] | null;
};

export type ArchivedFoundItem = Omit<ArchivedFoundItemRecord, 'category_id'> & {
  category: ItemCategory;
  // potential_matches: PotentialMatch[] | null;
};

type FoundItemInsert = Database['public']['Tables']['found_items']['Insert'];
type GenericPostFoundItem = Omit<
  FoundItemInsert,
  'image' | 'expiry_date' | 'organisation_id'
>;
export type PostFoundItemPayloadData =
  | ({ imageDetection: false } & GenericPostFoundItem)
  | ({
      imageDetection: true;
    } & Omit<
      GenericPostFoundItem,
      'category_id' | 'description' | 'is_high_value_item'
    >);
