import { supabase } from '../../../supabase/supabaseClient';
import { Inquiry, ItemCategory } from '../../../../types/supabase/collections';
import { inquiryFields } from './getInquiries';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function getInquiry(id: number): Promise<Inquiry> {
  const { data, error } = await supabase
    .from('inquiries')
    .select(inquiryFields)
    .eq('id', id)
    .single();

  assertNoSupabaseError(data, error, 'getInquiry error:', {}, true);

  return {
    ...data,
    category: data.categories as ItemCategory
  };
}
