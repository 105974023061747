import { supabase } from "../../../servieces/supabase/supabaseClient";

export const singupWithEmail = async (args: {
  email: string;
  password: string;
  fullName: string;
}): Promise<{ success: boolean }> => {
  try {
    const { email, password, fullName } = args;

    const { error } = await supabase.auth.signUp({
      email: email,
      password: password,
      options: {
        data: {
          full_name: fullName,
          avatar_url: "",
        },
      },
    });

    if (!error) return { success: true };

    alert("der skete en fejl");
    console.error(`Error creating user: ${error.message}`);
    return { success: false };
  } catch (error) {
    console.error("Error creating user:", error);
    return { success: false };
  }
};
