import React from "react";
import styles from "./actionsTab.module.css";

import { TabLayout } from "../../components/TabLayout/TabLayout";
import { PossibleMatchesTable } from "./components/PossibleMatchesTable/PossibleMatchesTable";
import { AwaitingPickupTable } from "./components/AwaitingPickupTable/AwaitingPickupTable";
import { ExpiredOrLeftBehindTable } from "./components/ExpiredOrLeftBehindTable/ExpiredOrLeftBehindTable";

export const ActionsTab = () => {
  return (
    <TabLayout>
      <div>
        <div className={styles.section}>
          <div className={styles.title}>Matches</div>
          <PossibleMatchesTable />
        </div>
        <div>
          <div className={styles.title}>Afventer afhentning</div>
          <AwaitingPickupTable />
        </div>
        <div>
          <div className={styles.title}>Udløbede genstande </div>
          <ExpiredOrLeftBehindTable />
        </div>
      </div>
    </TabLayout>
  );
};
