import React, { FC } from 'react';
import styles from './emailTypeOptions.module.css';
import {
  EmailType,
  emailTypes
} from '../../../../../../types/supabase/collections';

const getTypeInfo = (
  type: EmailType
): {
  name: string;
  description: string;
} => {
  switch (type) {
    case 'INQUIRY_CREATED':
      return {
        name: 'efterlysning oprettet',
        description: 'Sendes til person som opretter efterlysning'
      };
    case 'WE_ARE_STILL_SEARCHING':
      return {
        name: 'vi leder stadig',
        description:
          'efterlyst genstand blev er endnu ikke fundet, men vi leder stadig'
      };
    case 'ITEM_FOUND_READY_FOR_PICKUP':
      return {
        name: 'genstand fundet og klar til afhentning',
        description: 'Sendes til personer hvis genstand er blevet matched'
      };

    case 'WE_DIDNT_FIND_YOUR_ITEM':
      return {
        name: 'efterlyst genstand blev ikke fundet',
        description: 'Sendes til personer hvis genstand er blevet matched'
      };
    case 'X_DAYS_LEFT_TO_PICKUP':
      return {
        name: 'Påmindelse om at afhnete genstand',
        description:
          'Sendes til person som endnu ikke har afhentet deres genstand'
      };
    case 'ITEM_NEVER_PICKED_UP':
      return {
        name: 'efterlyst genstand blev ikke afhentet',
        description:
          'Sendes til personer hvis genstand er fundet, men aldrig blev afhentet'
      };

    default:
      return {
        name: '.',
        description: '.'
      };
  }
};
interface Props {
  selectedEmailType: EmailType;
  onChange: (template: EmailType) => void;
}

const EmailTypeOptions: FC<Props> = ({ selectedEmailType, onChange }) => {
  return (
    <div className={styles.matchOptions__container}>
      {emailTypes.map((type, i) => {
        const info = getTypeInfo(type);
        return (
          <div
            key={i}
            className={`${styles.container} ${selectedEmailType === type ? styles.container__selected : ''}`}
            onClick={(e: any) => {
              e.preventDefault();
              onChange(type);
            }}
          >
            <div className={styles.container__right}>
              <div className={styles.description}>{info.name}</div>
              <div className={styles.category}>{info.description}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EmailTypeOptions;
