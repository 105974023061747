import styles from './usersTab.module.css';
import React, { FC, useEffect, useState } from 'react';
import { Box as MuiBox, Chip, TextField } from '@mui/material';
import { TabLayout } from '../../components/TabLayout/TabLayout';
import { Role } from '../../../../types/supabase/collections';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import { useAuth } from '../../../../contexts/AuthContext/AuthContext';
import Api from '../../../../servieces/Api/Api';
import GenericTable, {
  Column
} from '../../../../components/GenericTable/GenericTable';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import { EllipsisMenu } from '../../../../components/EllipsisMenu/EllipsisMenu';
import { objectsAreEqual } from '../../../../utils/arraysAreEqual';
import { useSnackbar } from '../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { Button } from '../../../../sharedPacakge/components/Button/Button';
import { LoadingSpinner } from '../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import logger from '../../../../sharedPacakge/logger';
import { sendAdminInviteEmail } from './sendAdminInviteEmail';
import { getDateDaysFromNow } from '../../../../utils/dateInXDays';
import { AdminOrInvite } from '../../../../servieces/Api/methods/get/getAdminList';
import { supabase } from '../../../../servieces/supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../../servieces/supabase/supabaseErrorHandling';
import { daysUntilDate } from '../../../../utils/daysUntilDate';

type UserRoleProfile = {
  id: string;
  email: string;
  fullName: string;
};

export const UsersTab: FC = () => {
  const { user } = useAuth();
  const { location, userRole, organisation } = useLocationData();
  const [selectedTab, setSelectedTab] = useState<Role>(Role.LocationAdmin);
  const { showSnackbar } = useSnackbar();
  const [admins, setAdmins] = useState<AdminOrInvite[]>([]);
  const [inputEmail, setInputEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [loadingInitialAdmins, setLoadingInitialAdmins] =
    useState<boolean>(false);

  const fetchAdminList = () => {
    if (!organisation || !location || !user) return;

    setLoadingInitialAdmins(true);
    const apiPayload = {
      getUsersWithRole: selectedTab,
      locationId: location.id,
      organisationId: organisation.id
    };

    Api.get
      .adminsAndInvites(apiPayload)
      .then((response) => {
        setLoadingInitialAdmins(false);
        setAdmins(response);
      })
      .catch(() => showSnackbar('Noget git galt', 'error'));
  };

  useEffect(() => {
    fetchAdminList();
  }, [user, selectedTab, organisation, location]);

  if (!organisation || !user) return null;

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (inputEmail === '' && !inputEmail.includes('@')) {
      showSnackbar('Invalid email', 'error');
      return;
    }
    if (admins.find((admin) => admin.email === inputEmail)) {
      showSnackbar('Bruger eksisterer allerede');
      return;
    }
    setLoading(true);

    try {
      const userProfile = await Api.get.userProfileFromEmail(inputEmail);
      if (!userProfile) {
        const newAdmin = {
          email: inputEmail,
          role: selectedTab,
          expiry_date: getDateDaysFromNow(30).toISOString(),
          ...(selectedTab === Role.LocationAdmin
            ? { location_id: location.id }
            : {}),
          organisation_id: organisation.id
        };
        const { data: newAdminData, error: newAdminError } = await supabase
          .from('user_invites')
          .insert(newAdmin)
          .select('id, expiry_date')
          .single();

        assertNoSupabaseError(
          newAdminData,
          newAdminError,
          'getAllAdmins userInvitesError:',
          newAdmin,
          true
        );

        await sendAdminInviteEmail(
          selectedTab,
          inputEmail,
          location,
          organisation,
          false
        );

        setAdmins((prevState) => [
          ...prevState,
          {
            id: newAdminData.id,
            isUserInvite: true,
            email: inputEmail,
            fullName: inputEmail.split('@')[0],
            expiryDate: newAdminData.expiry_date
          }
        ]);
        setInputEmail('');
        showSnackbar('administratorer blev opdateret');
        setLoading(false);
        return;
      }

      const newId = await Api.post.admin({
        userIdToAdd: userProfile.id,
        roleType: selectedTab,
        locationId: location.id,
        organisationId: organisation.id
      });
      setLoading(false);
      setInputEmail('');
      setAdmins((prevState) => [
        ...prevState,
        {
          id: newId,
          userId: userProfile.id,
          email: userProfile.email,
          fullName: userProfile.fullName,
          isUserInvite: false
        }
      ]);
      showSnackbar('administratorer blev opdateret');

      await sendAdminInviteEmail(
        selectedTab,
        inputEmail,
        location,
        organisation,
        true
      );
    } catch (error) {
      setLoading(false);
      logger.error('UserTab error adding admin');
      showSnackbar('der skete en fejl', 'error');
    }
  };

  const onRemoveAdmin = async (adminOrInvite: AdminOrInvite) => {
    try {
      if (adminOrInvite.isUserInvite) {
        await Api.delete.userInvite(adminOrInvite.id);
      } else {
        await Api.delete.admin(adminOrInvite.id);
      }

      showSnackbar(`adminstrator blev fjernet`);
      setAdmins((prevState) =>
        prevState.filter((admin) => !objectsAreEqual(adminOrInvite, admin))
      );
    } catch (error) {
      showSnackbar('Der skete en fejl');
    }
  };

  const tableColoumns: Column<AdminOrInvite>[] = [
    {
      id: 'fullName',
      label: 'Fulde navn',
      render: (row) =>
        row.isUserInvite
          ? row.fullName
          : `${row.userId === user.id ? '(dig) ' : ''}${row.fullName}`
    },
    {
      id: 'email',
      label: 'Email',
      render: ({ email }) => email
    },
    {
      id: 'Role',
      label: 'Administrator af',
      render: () => translateAdminType(selectedTab)
    },
    {
      id: 'isUserInvite',
      label: '',
      render: (row) =>
        row.isUserInvite ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <Chip
              label={`Invitation`}
              variant="outlined"
              style={{
                width: 'fit-content'
              }}
            />
            <small>
              Udløber om {daysUntilDate(new Date(row.expiryDate))} dage
            </small>
          </div>
        ) : (
          ''
        )
    }
  ];

  const handleInputChange = (e: any) => {
    setInputEmail(e.target.value);
  };

  return (
    <TabLayout>
      <div className={styles.container}>
        {userRole === Role.OrganisationAdmin && (
          <div className={styles.tabsContainer}>
            <Button
              className={`${styles.tabButton} ${selectedTab === Role.LocationAdmin ? styles.tabButton__selected : ''}`}
              onClick={(e) => setSelectedTab(Role.LocationAdmin)}
            >
              Lokation
            </Button>
            <Button
              className={`${styles.tabButton} ${selectedTab === Role.OrganisationAdmin ? styles.tabButton__selected : ''}`}
              onClick={(e) => setSelectedTab(Role.OrganisationAdmin)}
            >
              Organisation
            </Button>
          </div>
        )}
        <form className={styles.form} onSubmit={onSubmit}>
          {submitLoading || loadingInitialAdmins ? (
            <LoadingSpinner />
          ) : (
            <>
              <h3 className={styles.title}>
                {selectedTab === Role.LocationAdmin
                  ? `Administratorer af lokation: ${location.name}`
                  : `Administratorer af organisation: ${organisation.name}`}
              </h3>
              <GenericTable<AdminOrInvite>
                columns={tableColoumns}
                data={admins}
                rowKey="id"
                actionsColumn={(userProfile) => {
                  if (
                    selectedTab === Role.OrganisationAdmin &&
                    admins.length === 1
                  ) {
                    return ''; // so you can't remove the last organisation admin
                  }
                  return (
                    <EllipsisMenu
                      options={[
                        {
                          label: 'Fjern',
                          icon: <PersonRemoveRoundedIcon />,
                          onClick: () => onRemoveAdmin(userProfile)
                        }
                      ]}
                    />
                  );
                }}
              />
              <div
                style={{
                  width: '300px'
                }}
              >
                <MuiBox
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  sx={{ marginTop: '50px', marginBottom: '10px' }}
                >
                  <MuiBox
                    display="flex"
                    flexWrap="wrap"
                    gap={1}
                    alignItems="center"
                    border={1}
                    borderColor="grey.400"
                    borderRadius={1}
                    p={1}
                  >
                    <TextField
                      type="email"
                      value={inputEmail}
                      onChange={handleInputChange}
                      disabled={loading}
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      sx={{ flex: 1, minWidth: '150px' }}
                      placeholder="Tilføj ny administrator via email"
                    />
                  </MuiBox>
                </MuiBox>
                <Button disabled={submitLoading} type="submit" fullWidth>
                  Udfør
                </Button>
              </div>
            </>
          )}
        </form>
      </div>
    </TabLayout>
  );
};

function translateAdminType(role: Role): string {
  if (role === Role.OrganisationAdmin) return 'Organisationen';
  if (role === Role.LocationAdmin) return 'Lokationen';
  else return '';
}
