import React, { FC, ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.css";
import { Divider } from "@mui/material";
import { SetState } from "../../types/utilityTypes";

export interface ModalProps {
  title: string;
  children: ReactNode;
  setIsOpen: SetState<boolean>;
  className?: string;
}

export const Modal: FC<ModalProps> = ({
  title,
  children,
  setIsOpen,
  className,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const portal = document.getElementById("modal-root");

  if (!portal) {
    console.warn("Modal can't open, there is no modal root in the html");
    return null;
  }

  const clickOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    // Close modal when clicking outside the modal content
    if (e.target === e.currentTarget) {
      setIsOpen(false);
    }
  };

  const clickModal = (e: React.MouseEvent<HTMLDivElement>) => {
    // Prevent default action here if necessary
    // e.preventDefault();
    // Allow event propagation within the modal content
    // e.stopPropagation();
  };

  return ReactDOM.createPortal(
    <div className={styles.background} onClick={clickOutside}>
      <div
        className={`${styles.modal} ${className ?? ""}`}
        onClick={clickModal}
      >
        <div className={styles.modalHeader}>
          <div className={styles.headerColumn}>
            <a className={styles.closeButton} onClick={() => setIsOpen(false)}>
              Annuller
            </a>
          </div>
          <div className={styles.headerColumn}>
            <p className={styles.title}>{title}</p>
          </div>
        </div>
        <Divider />
        <div className={styles.contentContainer}>{children}</div>
      </div>
    </div>,
    portal,
  );
};
