import styles from './header.module.css';
import React, { FC, ReactNode } from 'react';
import InfoButton from './InfoModal/InfoButton';
import { LocationSelectButton } from './LocationSelect/LocationSelectButton';
import { OrganisationSelectButton } from './OrganisationSelectModal/OrganisationSelectButton';
import { darkFindyLogo } from '../../../../assets';
import { useIsMobile } from '../../../../utils/hooks/useIsMobile';
import NewFoundItemButton from '../Sidebar/NewFoundItemButton/NewFoundItemButton';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import Page from '../../../Page';
import { Button } from '../../../../sharedPacakge/components/Button/Button';

interface Props {
  navDrawerButton?: () => ReactNode;
}
export const Header: FC<Props> = ({ navDrawerButton }) => {
  const { organisation } = useLocationData();
  const isMobile = useIsMobile();

  return (
    <div className={styles.header}>
      {navDrawerButton && navDrawerButton()}
      <div className={styles.logo}>
        <img
          src={darkFindyLogo}
          alt="findy-logo"
          width={100}
          height={40}
          style={{ objectFit: 'contain' }}
        />
      </div>
      <div className={styles.contentRight}>
        {!isMobile && <InfoButton />}
        <LocationSelectButton />
        <OrganisationSelectButton />
      </div>
    </div>
  );
};
