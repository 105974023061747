import React, {FC, useEffect, useState} from "react";
import styles from "./previewInquiryFormt.module.css";
import {useLocationData} from "../../../../../../contexts/LocationDataContext/LocationDataContext";
import {CustomInquiryFormStyling} from "../../EditInquiryForm";
import Page from "../../../../../Page";

interface Props {
    previewMode: "mobile" | "desktop"
    stylingObject: CustomInquiryFormStyling
}

export const PreviewInquiryForm: FC<Props> = ({previewMode, stylingObject  }) => {
    const { organisation } = useLocationData();
    const [previewUrl, setPreviewUrl] = useState<string>("");



    useEffect(() => {
        if(!organisation) return
        // const iframeUrl = "http://localhost:4000/da/9ka/Vitaliys%20Organisation/iframe"
        setPreviewUrl(Page.external.lostSomethingIframe(organisation.id, organisation.name));
    }, [organisation]);

    useEffect(() => {
        const iframe = document.getElementById('preview-inqruiy-iframe');
        (iframe as any).contentWindow.postMessage({ action: 'overrideStyling', styling: stylingObject }, '*')
    }, [ stylingObject]);
    
  return (
    <div className={styles.container}>
  
      <div style={{width: 640 }}>
        <div style={{  display: "flex", justifyContent: "center",
        }} className={styles.iframe__container}>
          <iframe id="preview-inqruiy-iframe"
            src={previewUrl}
            style={{
              border: "none",
              height: previewMode === "desktop" ? "740px" : "896px",
              width: previewMode === "desktop" ? "600px" : "414px",
            }}
          />
        </div>
      </div>
    </div>
  );
};
