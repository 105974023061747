import React, { FC, useEffect, useState } from 'react';
import styles from './FoundItemsTable.module.css';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { TabLayout } from '../../components/TabLayout/TabLayout';
import FoundItemsTable from '../../components/FoundItemsTable/FoundItemTable';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../servieces/Api/Api';
import {
  ArchivedFoundItem,
  FoundItem
} from '../../../../types/supabase/collections';
import useFilters from '../../hooks/useFilters';
import { useEventListener } from '../../../../contexts/EventsProvider.ts/EventsProvider';
import { Switch } from '../../../../sharedPacakge/components/Switch/Switch';
import { TextField } from '@mui/material';
import { AutocompleteInput } from '../../../../components/AutoCompleteInput/AutoCompleteInput';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const FoundItemsTab: FC = () => {
  const { location } = useLocationData();
  const [filters, udpateFilter] = useFilters();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [foundItems, setFoundItems] = useState<
    FoundItem[] | ArchivedFoundItem[]
  >([]);

  const removeFoundItemLocally = (id: number) => {
    setFoundItems((prevState) => prevState.filter((item) => item.id !== id));
  };

  const fetchFoundItems = () => {
    if (!location) return;
    Api.get
      .foundItems({
        location_id: location.id,
        archived: filters.isArchived
      })
      .then(
        (foundItemsResponse) =>
          foundItemsResponse && setFoundItems(foundItemsResponse)
      );
    setIsLoading(false);
  };

  useEventListener('foundItemUploaded', fetchFoundItems);
  useEffect(() => {
    fetchFoundItems();
  }, [location, filters.isArchived]);

  const renderNoFoundItems = () => (
    <>
      <h2 className={styles.noItems__text}>
        {filters.isArchived
          ? 'Ingen afviklet genstande endnu'
          : 'Ingen fundne genstande endnu'}
      </h2>
      <p
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '5px'
        }}
      >
        Opret fundne genstande
        <PostAddIcon />
      </p>
    </>
  );

  const updateFoundItem = (
    id: number,
    newValue: FoundItem | ArchivedFoundItem
  ) => {
    setFoundItems((prevState) =>
      prevState.map((item) => (item.id === id ? newValue : item))
    );
  };

  const queryFilter = (item: FoundItem) =>
    filters.query
      ? item.category.name_danish
          .toLowerCase()
          .includes(filters.query.toLowerCase()) ||
        item.description.toLowerCase().includes(filters.query.toLowerCase())
      : true;

  const filteredFoundItems = filters.query
    ? foundItems.filter((item) => queryFilter(item))
    : foundItems;

  const uniqueQueryFilterOptions = Array.from(
    new Set([
      ...filteredFoundItems.map((item) => item.description),
      ...filteredFoundItems.map((item) => item.category.name_danish)
    ])
  );

  return (
    <TabLayout>
      <div className={styles.container}>
        <div className={styles.filters__container}>
          <div className={styles.searchBar__container}>
            <AutocompleteInput
              label="Søg"
              placeholder="Kategori eller beskrivelse"
              icon={<SearchOutlinedIcon sx={{ color: '#C4C4C4 !important' }} />}
              options={uniqueQueryFilterOptions}
              value={filters.query || ''}
              onChange={(newValue) => udpateFilter({ query: newValue })}
              maxOptions={7}
            />
          </div>
          <div>
            <Switch
              uniqueId="foundItem-archive-switch"
              state={!!filters.isArchived}
              label="Vis afviklede"
              onChange={() => udpateFilter({ isArchived: !filters.isArchived })}
            />
          </div>
        </div>

        {filters.isArchived ? (
          <FoundItemsTable<ArchivedFoundItem>
            isLoading={isLoading}
            foundItems={filteredFoundItems as ArchivedFoundItem[]}
            updateFoundItem={updateFoundItem}
            callbacks={{
              onArchiveCallback: removeFoundItemLocally,
              onUnArchiveCallback: removeFoundItemLocally
            }}
            options={{
              renderNoFoundItems: renderNoFoundItems,
              columns: [
                'image',
                'category',
                'description',
                'found_at_date',
                'id',
                'archive_reason'
              ]
            }}
          />
        ) : (
          <FoundItemsTable<FoundItem>
            isLoading={isLoading}
            foundItems={filteredFoundItems}
            updateFoundItem={updateFoundItem}
            callbacks={{
              onArchiveCallback: removeFoundItemLocally,
              onUnArchiveCallback: removeFoundItemLocally
            }}
            options={{
              renderNoFoundItems: renderNoFoundItems,
              columns: [
                'image',
                'category',
                'description',
                'found_at_date',
                'id',
                'status'
              ]
            }}
          />
        )}
      </div>
    </TabLayout>
  );
};

export default FoundItemsTab;
